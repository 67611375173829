.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid{
  display: flex;
  justify-content: center;
  background-color: #DDDDDD;
  margin:15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: calc(100% - 50px);
}

/* .node{
  height:40px;
  width:40px;
} */

.info{
  width: 25%;
  margin: 0px 20px 0px 20px;
}

.hover{
  background-color: #00ff00;
}

.node_active{
  background-color: blue;
}

.wall_left{
  border-left: 2px black solid;
  transition-duration: .2s;
}

.wall_bottom{
  border-bottom: 2px black solid;
  transition-duration: .2s;
}

.wall_right{
  border-right: 2px black solid;
}

.wall_top{
  border-top: 2px black solid;
}

.node_end{
  background-color: #ff0000 !important;
}

.node_start{
  background-color: #00ff00 !important;
}

.node:not(.wall_left) {
  border-left: 2px #FFFFFF00 solid;
}

.node:not(.wall_bottom) {
  border-bottom: 2px #FFFFFF00 solid;
}

.menu{
  padding:20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.algorithms, .button{
  background-color: #ADD8E6;
  padding:7.5px;
  border-radius: 5px;
  border:none;
  outline: none;
  cursor: pointer;
}

.clear{
  background-color: red;
}

.settings{
  background-color: #AEAEAE;
}

.key{
  background-color: #DDDDDD;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin:15px;
  border-radius: 5px;
}

.key_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:10px;
}

.key_node_end{
  background-color: #ff0000;
  height:20px;
  width:20px;
}

.key_node_start{
  background-color: #00ff00;
  height:20px;
  width:20px;
}

.key_node_via-point{
  background-color: #0000ff;
  height:20px;
  width:20px;
}
.key_node_path{
  background-color: yellow;
  height:20px;
  width:20px;
}
.key_node_visited_node{
  background-color: peru;
  height:20px;
  width:20px;
}
.text_input{
  width:50px;
  height:20px;
}

.settings_container{
  position:absolute;
  background-color: #AEAEAE;
  border-radius: 5px;
  margin-top: 10px;
  padding:5px 20px 5px 20px;
  box-shadow: #00000085 0px 0px 30px 0px;
}

.message{
  padding: 50px
}
.column{
  display: flex;
  flex-direction: column;
  align-items: center;
}

a.link, a.link:visited, a.link:hover, a.link:active{
  text-decoration: none;
  color:"grey";
  
}


@keyframes visit_node {
  0% {
    background-color: inherit;
  }
  100% {
    background-color: peru;
  }
}

@keyframes visit_node_path{
  0% {
    background-color: inherit;
  }
  80%{
    background-color: peru;
  }
  100% {
    background-color: yellow;
  }
}
